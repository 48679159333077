import { useEffect, useContext } from 'react';
import { useErrorBoundary } from 'react-error-boundary';
import { useQuery } from 'react-query';

import { ClassroomContext } from '@/data/ClassroomContext';
import { getClassroomWithInstructors } from '@/graphql/custom';
import { executeRequest } from '@/modules/api';
import { useFlags } from '@/utils/flags';
import { isEmpty } from '@/utils/helpers';
import { useAcceptInvitations } from '@/utils/useAcceptInvitations';

import { useProvider } from './ProviderContext';

const DELAYS = [500, 1000, 1500, 2000];

const useQueryOptions = {
    refetchOnWindowFocus: false,
    staleTime: 5000,
    retryDelay: (retryNum) => DELAYS[retryNum],
};

const fetchClassroom = async ({
    classroomId,
    provider,
    withStudentRoster,
    withSubProviderCapacity,
}) => {
    const decodedClassroomId = decodeURIComponent(classroomId);
    const {
        getClassroom: payload,
        listClassroomRelationships,
        studentRoster,
    } = await executeRequest({
        operation: getClassroomWithInstructors,
        params: {
            classroomId: decodedClassroomId,
            providerArn: provider.arn,
            withStudentRoster,
            withSubProviderCapacity,
        },
        flag: true,
    });
    payload.classroom.trainingProviderName = provider.name;
    payload.classroom.trainingProviderType = provider.type;
    payload.classroom.subProviderData = provider.subProviderData;
    payload.classroom.requiresSubProvider = provider.requiresSubProvider;
    if (listClassroomRelationships?.classroomUsers) {
        payload.classroom.instructors = listClassroomRelationships.classroomUsers.map(
            (classroomUser) => classroomUser.email,
        );
    }
    if (studentRoster?.classroomUsers) {
        payload.classroom.studentRoster = studentRoster.classroomUsers.map((item) => item);
    }
    return payload;
};

export const useClassroomData = (classroomId, { withConsistencyRetry = false } = {}) => {
    const flags = useFlags();

    const { showBoundary } = useErrorBoundary();
    const { classData, loadInitialState, ...rest } = useContext(ClassroomContext);
    const provider = useProvider();
    const acceptedInvitations = useAcceptInvitations();

    const { data, error, refetch } = useQuery(
        [classroomId, provider],
        () => {
            if (classroomId && provider?.arn) {
                return fetchClassroom({
                    classroomId,
                    provider,
                    withStudentRoster: flags.studentRoster,
                    withSubProviderCapacity: flags.multiGilmoreId,
                });
            }
        },
        {
            ...useQueryOptions,
            retry: (retryNum, err) => {
                let toRetry = false;
                if (
                    err.statusCode === 403 &&
                    acceptedInvitations &&
                    acceptedInvitations.length > 0
                ) {
                    toRetry = true;
                } else if (err.statusCode >= 500) {
                    toRetry = true;
                }
                return withConsistencyRetry && toRetry && retryNum < DELAYS.length;
            },
        },
    );

    useEffect(() => {
        if (error) {
            showBoundary(error);
        } else if (!isEmpty(data)) {
            loadInitialState(data);
        }
    }, [data, loadInitialState, error, showBoundary]);

    // Helper fields for determining custom courses
    const hasNoLabs = !classData?.content?.length;
    const hasNoJams = !classData?.jamTrainings?.trainings?.length;
    return { classData, hasNoLabs, hasNoJams, refetch, ...rest };
};
